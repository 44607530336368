import { useProducts } from "@sushicorp/contexts";
import { useFetchUser } from "@sushicorp/services";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./Navbar.styles";
import { NavbarProps as Props } from "./Navbar.types";
import Button from "../Button/Button";
import Logo from "../Logo/Logo";
import SearchBarProducts from "../SearchBarProducts/SearchBarProducts";
import SelectAddressDropdown from "../SelectAddressDropdown/SelectAddressDropdown";
import CartButton from "../navigation/CartButton/CartButton";
import LoyaltyTabsMenuItem from "../navigation/LoyaltyTabsMenuItem/LoyaltyTabsMenuItem";
import UserTabsMenuItem from "../navigation/UserTabsMenuItem/UserTabsMenuItem";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import { useTalkShop } from "contexts/talkShop/talkShop.context.hooks";
import useI18n from "hooks/useI18n";
import useWindowSize from "hooks/useWindowSize";

import SearchSVG from "../../../../public/assets/images/search.svg";

const { FEATURE_FLAGS, BREAKPOINTS } = CONSTANTS;
const { desktop } = BREAKPOINTS;
const { WITH_LOYALTY } = FEATURE_FLAGS;
const { WITH_SELECT_ADDRESS_DROPDOWN, WITH_TALK_SHOP } = FEATURE_FLAGS;
const { WITH_PURCHASE, WITH_BOTTOM_SEARCH, WITH_ORDER_NOW } = FEATURE_FLAGS;
const { WITH_GREETING_TEXT, WITH_BOTTOM_RESPONSIVE_BUTTONS } = FEATURE_FLAGS;

const Navbar: React.FC<Props> = props => {
  const { className, showBrand = false, icon, product } = props;
  const { showCart = true } = props;
  const notHome = !showBrand ? "not-home" : "";
  const isShowCart = !showCart ? "Navbar__btn--hidden" : null;
  const t = useI18n();
  const { setOpenSearchBarDrawer } = useProducts();
  const { width } = useWindowSize();
  const { isAnonymous } = useAuth();
  const authContext = useAuth();
  const { data: user } = useFetchUser(authContext);
  const { name } = user ?? {};
  const { talkShopProvider, talkShopIdentifier } = useTalkShop();
  const { push, pathname } = useRouter();
  const talkShop = WITH_TALK_SHOP && talkShopProvider && talkShopIdentifier;
  const isDesktop = width >= desktop + 176;
  const greetingText = `${t.profile.greeting} ${name ?? ""}`;
  const isInHome = pathname === "/";
  const secondaryStyle = isInHome ? "" : "Navbar__top__secondary--hide";

  const renderCartButton = () => {
    if (!WITH_PURCHASE) return null;
    return (
      <CartButton
        className={`Navbar__btn Navbar__btn--cart ${isShowCart}`}
        product={product}
      />
    );
  };

  const renderBottomSearchButton = () => {
    if (!WITH_BOTTOM_SEARCH) return null;
    return (
      <>
        <SearchBarProducts className="Navbar__search-bar Navbar__search-bar--responsive" />
      </>
    );
  };

  const renderOrderNowOrDropDown = () => {
    if (!WITH_SELECT_ADDRESS_DROPDOWN) return null;
    if (!WITH_ORDER_NOW)
      return <SelectAddressDropdown className="Navbar__dropdown" />;
    if (WITH_BOTTOM_SEARCH)
      return <SelectAddressDropdown className="Navbar__dropdown" />;
    if (pathname === "/")
      return (
        <div className="Navbar__order-now-container">
          <Button
            className="Navbar__btn Navbar__btn--order-now"
            onClick={() => push("/categories")}
          >
            {t.common.orderNow}
          </Button>
        </div>
      );
    return <SelectAddressDropdown className="Navbar__dropdown" />;
  };

  const iconNode = () => {
    return (
      <div className="Navbar__icon">
        {isAnonymous ? (
          <Button
            className="Navbar__btn Navbar__btn--sign-in Navbar__btn--sign-in--mobile"
            color="white"
            onClick={() => push("/signin")}
          >
            {t.common.enter}
          </Button>
        ) : null}
        {!WITH_BOTTOM_SEARCH ? (
          <Button
            className="Navbar__btn Navbar__btn--search"
            onClick={() => setOpenSearchBarDrawer(prev => !prev)}
          >
            <SearchSVG />
          </Button>
        ) : null}
        {renderCartButton()}
        <UserTabsMenuItem
          hideTitle
          className="Navbar__btn Navbar__btn--profile"
          active={false}
        />
      </div>
    );
  };

  const renderSecondaryButton = () =>
    isAnonymous ? (
      <Button
        className={`Navbar__top__secondary__button ${secondaryStyle}`}
        onClick={() => push("/signin")}
      >
        {t.common.enter}
      </Button>
    ) : (
      <p className={`Navbar__top__secondary__greeting ${secondaryStyle}`}>
        {greetingText}
      </p>
    );

  const navbarClickHandler = () => {
    if (!pathname.includes("/profile/orders")) return;
    push("/");
  };

  return (
    <Styles
      className={`Navbar ${className}`}
      withBottomSearch={WITH_BOTTOM_SEARCH}
      withBottomResponsiveButtons={WITH_BOTTOM_RESPONSIVE_BUTTONS}
      talkShop={!!talkShop}
      id="navbar"
    >
      <main className="Navbar__content">
        <div className="Navbar__top__wrapper">
          <div className="Navbar__top">
            <div className={`Navbar__top Navbar__top--brand ${notHome}`}>
              {talkShop ? (
                <Logo className="Navbar__logo" onClick={navbarClickHandler} />
              ) : (
                <Logo className="Navbar__logo" />
              )}
              {WITH_SELECT_ADDRESS_DROPDOWN ? (
                <SelectAddressDropdown className="Navbar__dropdown Navbar__dropdown--responsive" />
              ) : null}
              <SearchBarProducts className="Navbar__search-bar Navbar__search-bar--top" />
              {WITH_LOYALTY ? (
                <LoyaltyTabsMenuItem
                  hideTitle
                  className="Navbar__btn Navbar__btn--loyalty"
                  active={false}
                />
              ) : null}
            </div>
            <div className="Navbar__top Navbar__top__buttons">
              {isAnonymous ? (
                <Button
                  className="Navbar__btn Navbar__btn--sign-in"
                  color="white"
                  onClick={() => push("/signin")}
                >
                  {t.common.enter}
                </Button>
              ) : WITH_GREETING_TEXT ? (
                <p className="Navbar__greeting-text">{greetingText}</p>
              ) : null}
              <CartButton
                className="Navbar__btn Navbar__btn--cart"
                product={product}
              />
              {isDesktop && WITH_ORDER_NOW ? (
                <Button
                  className="Navbar__btn Navbar__btn--order-now"
                  color="primary"
                  onClick={() => push("/categories")}
                >
                  {t.common.orderNow}
                </Button>
              ) : null}
              <UserTabsMenuItem
                hideTitle
                className="Navbar__btn Navbar__btn--profile"
                active={false}
              />
            </div>
            <div className={`Navbar__top Navbar__top--responsive ${notHome}`}>
              {icon ?? iconNode()}
            </div>
          </div>
          <div className="Navbar__top__second-row">
            {WITH_SELECT_ADDRESS_DROPDOWN ? (
              <SelectAddressDropdown className="Navbar__dropdown" />
            ) : null}
          </div>
        </div>
        {WITH_BOTTOM_RESPONSIVE_BUTTONS && (
          <div className="Navbar__top--responsive Navbar__top--responsive-brand">
            {renderBottomSearchButton()}
            {isInHome ? renderSecondaryButton() : null}
            {renderOrderNowOrDropDown()}
          </div>
        )}
      </main>
    </Styles>
  );
};

Navbar.defaultProps = {
  className: ""
};

export default Navbar;
